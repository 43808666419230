import React from "react";
import { PureComponent } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";


type Run = {
    id: number,
    metadata: Map<string, string>,
    timestamp: number,
    screenshot_count: number,
    compared_with: number,

}

type ProjectProps = {
    projectId?: string,
    meta_filter?: string | null,
    refresh: number,
};
type ProjectState = {
    runs: Run[],
};


class ProjectInner extends PureComponent<ProjectProps, ProjectState> {
    state: ProjectState = {
        runs: []
    };
    componentDidMount() {
        fetch("/" + this.props.projectId + "/runs")
            .then(response => response.json())
            .then(data => {
                this.setState({ runs: data });
            });
    }

    componentDidUpdate(prevProps: ProjectProps) {
        if (this.props.refresh !== prevProps.refresh) {
            fetch("/" + this.props.projectId + "/runs")
                .then(response => response.json())
                .then(data => {
                    this.setState({ runs: data });
                });
        }
    }

    render() {
        return (
            <Container fluid="lg">
                <Row>
                    <Col>&nbsp;</Col>
                </Row>
                <Row>
                    <Col>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>id</th>
                                    <th>Timestamp</th>
                                    <th>Number of screenshots</th>
                                    <th>Meta</th>
                                    <th>Latest comparison</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.runs
                                        .filter((run) => {
                                            if (this.props.meta_filter) {
                                                let filter = JSON.parse(this.props.meta_filter);
                                                for (let [key, value] of Object.entries(filter)) {
                                                    let key_found = false;
                                                    for (let [rkey, rvalue] of Object.entries(run.metadata)) {
                                                        if (key === rkey) {
                                                            key_found = true;
                                                        }
                                                        if (key === rkey && value !== rvalue) {
                                                            return false;
                                                        }
                                                    }
                                                    if (!key_found) {
                                                        return false;
                                                    }
                                                }
                                            }
                                            return true;
                                        })
                                        .map((run, index) => {
                                            let metadata = Object.entries(run.metadata);
                                            metadata.sort();
                                            return <tr key={index}>
                                                <td>{run.id}</td>
                                                <td>{(new Date(run.timestamp * 1000)).toLocaleString()}</td>
                                                <td>{run.screenshot_count}</td>
                                                <td>{
                                                    metadata.map(([key, value]) => {
                                                        return <div key={key}>{key}: {value}</div>
                                                    })
                                                }</td>
                                                <td>{run.compared_with ? <a href={`/project/${this.props.projectId}/run/${run.id}/compare/${run.compared_with}`}>{run.compared_with}</a> : "view run"}</td>
                                            </tr>
                                        })
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container >
        );
    }
}

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Project(props: ProjectProps) {
    let params = useParams();
    let meta_filter = useQuery().get("meta");

    if (props.projectId === undefined) {
        return <ProjectInner {...props} projectId={params.project_id} meta_filter={meta_filter} />;
    } else {
        return <ProjectInner {...props} meta_filter={meta_filter} />;
    }
};
